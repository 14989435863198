import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ClienteIcon from '@material-ui/icons/PersonPinCircleSharp';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SettingsIcon from '@material-ui/icons/HomeWork';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SearchIcon from '@material-ui/icons/Search';
import ExitIcon from '@material-ui/icons/ExitToApp';
import SvgIcon from '@material-ui/core/SvgIcon';
import MapIcon from '@mui/icons-material/Map';

import { withRouter, useHistory } from 'react-router-dom';
import { logout, getUser, PodeAcessarRelatorios } from '../Router/auth';
import { AjusteEnderecoRouteStr, LoginRoute } from '../Router/Routing';
import { ReactComponent as notaFiscalIcon } from '../img/icon/notaFiscal.svg';

import CadastroIcon2 from '@material-ui/icons/Tune';
import { EmpresaIds } from '../Utils/Empresa';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	title: {
		flexGrow: 1,
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

function Main(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [podeAcessarRelatorios, setPodeAcessarRelatorios] = React.useState(PodeAcessarRelatorios()); 
	const [menus, setMenu] = React.useState([
		{ text: "Rotas", icon: <OndemandVideoIcon />, path: "/monitoramento" },
		{ text: "Busca Completa", icon: <SearchIcon />, path: "/buscaCompleta" },
	]);

	const [MenusCadastros, setMenusCadastros] = React.useState([
		{ text: "Rotas", icon: <CallSplitIcon />, path: "/rotas" },
		{ text: "Clientes", icon: <ClienteIcon />, path: "/clientes" },
		{ text: "Documentos", icon: <SvgIcon component={notaFiscalIcon} />, path: "/documentos" },
		(getUser() != null && (getUser().empresaId === 161 || getUser().empresaId === 69 || getUser().empresaId === EmpresaIds.EmpresaDoPedro))
			? { text: "Endereços com suspeita de divergência", icon: <MapIcon />, path: `${AjusteEnderecoRouteStr}/byEmpresa`} 
			: null,
		{ text: "Cadastros", icon: <CadastroIcon2 />, path: "/cadastros" },
	].filter(item => item !== null));

	const [MenusCadastrosRelatorioOnly, setMenusCadastrosRelatorioOnly] = React.useState([
		{ text: "Cadastros", icon: <CadastroIcon2 />, path: "/cadastros" },
	]);

	const [menusConfiguracoes, setMenusConfiguracoes] = React.useState([
		{ text: "Empresas", icon: <SettingsIcon />, path: "/empresas" },
		{ text: "BackEnd", icon: <SettingsEthernetIcon />, path: "/backend" },
	]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const sair = () => {
		logout();
		history.push(LoginRoute);
	}

	const history = useHistory();

	var pathSelect = props.location.pathname;
	if (props.location.pathname.indexOf('/', 1) !== -1)
		pathSelect = props.location.pathname.substr(0, props.location.pathname.indexOf('/', 1));

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap className={classes.title}>
						Portal - Grafos Tech
					</Typography>
					<Tooltip title="Sair">
						<IconButton color="inherit" onClick={sair}>
							<ExitIcon />
						</IconButton>
					</Tooltip>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</div>
				<Divider />
				<List>

					{menus.map((menu, index) => (
						<Tooltip title={menu.text}>
							<ListItem
								button
								key={menu.text}
								selected={pathSelect === menu.path}
								onClick={() => { history.push(menu.path); }}
							>
								<ListItemIcon>{menu.icon}</ListItemIcon>
								<ListItemText primary={menu.text} />
							</ListItem>
						</Tooltip>
					))}

				</List>
				{getUser() !== null && ['RLuOKTh1Ko', "Master", "AnaliseDeLogistica"].indexOf(getUser().role) > -1 ?
					<>
						<Divider />
						<List>

							{MenusCadastros.map((menu, index) => (
								<Tooltip title={menu.text}>
									<ListItem button key={menu.text} selected={pathSelect === menu.path} onClick={() => { history.push(menu.path); }}>
										<ListItemIcon>{menu.icon}</ListItemIcon>
										<ListItemText primary={menu.text} />
									</ListItem>
								</Tooltip>
							))}
						</List></> : podeAcessarRelatorios ? <>
							<Divider />
							<List>
								{MenusCadastrosRelatorioOnly.map((menu, index) => (
									<Tooltip title={menu.text}>
										<ListItem button key={menu.text} selected={pathSelect === menu.path} onClick={() => { history.push(menu.path); }}>
											<ListItemIcon>{menu.icon}</ListItemIcon>
											<ListItemText primary={menu.text} />
										</ListItem>
									</Tooltip>
								))}
							</List></> : <></>}
				{getUser() !== null && getUser().role === "RLuOKTh1Ko" ?
					<>
						<Divider />
						<List>
							{menusConfiguracoes.map((menu, index) => (
								<Tooltip title={menu.text}>
									<ListItem button key={menu.text} selected={pathSelect === menu.path} onClick={() => { history.push(menu.path); }}>
										<ListItemIcon>{menu.icon}</ListItemIcon>
										<ListItemText primary={menu.text} />

									</ListItem>
								</Tooltip>
							))}
						</List>
					</> : <></>}
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				{props.children}
			</main>
		</div>
	);
}
export default withRouter(Main);
