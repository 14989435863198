import { Component, useEffect, useState } from "react";
import { getFormatTimeToString,calcularDiferencaDatasParaString } from '../../Utils/FormatDateTime';

import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
    Card, CardContent
  } from '@mui/material';

import { withRouter } from "react-router-dom";
import { SharedSnackbarConsumer } from "../../Context/SharedSnackbar.context";
import { getRotasEmRoteirizacao } from "../../API/Rotas";
import { getRoteirizadorContainer } from "../../API/RoteirizadorContainer";

import { opcaoSituacaoRoteirizacao } from '../Rota/Config';
import ButtonRoteirizar from '../Rota/Components/ButtonRoteirizar';

import { VERMELHO_PASTEL } from '../../Utils/ColorsGrafos';

function Page(props) {
   
    const [data, setData] = useState([]);
    const [containers, setContainers] = useState([]);
    const [statusCount, setStatusCount] = useState();
    const [time, setTime] = useState(Date.now());


    const StatusCard = ({ situacaoRoteirizacao, status, count }) => {
        var cor = '';
        var ll = '';
        if (status === 'Container')
          {
              ll = 'Container Ativos (5 min)';
              if (count < 5)
                  cor = VERMELHO_PASTEL;
          }
        else if (status === 'Total')
        {
            ll = status;
            if (count > 10)
                cor = VERMELHO_PASTEL;
        }
        else if (situacaoRoteirizacao)
        {
            var busca = situacaoRoteirizacao.opcaoSituacaoRoteirizacao.find(x=>x.value === parseInt(status, 10));
            
            if (busca)
                ll = busca.label;
        }

        if (parseInt(status, 10) === 1 && count > 5) // Aguardando
            cor = VERMELHO_PASTEL;
        if (parseInt(status, 10) === 3 && count > 5) // Emroteirização
            cor = VERMELHO_PASTEL;
        if (parseInt(status, 10) === 4 && count > 0) // Erro
            cor = VERMELHO_PASTEL;
            
        return <Card sx={{ minWidth: 275, mb: 2, backgroundColor: cor }} >
        <CardContent >
          <Typography variant="h6" component="div">
          
              {ll}
          </Typography>
          <Typography variant="h5">
            {count} rotas
          </Typography>
        </CardContent>
      </Card>;
    }
        
        

      const StatusList = (situacaoRoteirizacao) => (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={4} key={-2}>
              <StatusCard status={'Container'} count={containers.length} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} key={-2}>
              <StatusCard status={'Total'} count={data.length} />
            </Grid>  

            {statusCount? 
          Object.entries(statusCount).map(([status, count]) => (
            <Grid item xs={12} sm={6} md={4} key={status}>
              <StatusCard status={status} situacaoRoteirizacao={situacaoRoteirizacao} count={count} />
            </Grid>
          )):<></>}

        
            
        </Grid>

      );

    useEffect(() => {
        atualizar();
        const interval = setInterval(() => {
            setTime(Date.now());
            atualizar();
        }, 1 * 15000);

        return () => {
            clearInterval(interval);
        }

    }, []);

    async function atualizar() {
      getRoteirizadorContainer().then(resp => {
        setContainers(resp);

      }).catch(error => {
          props.openSnackbar("Ocorreu algum erro 2", "error");
      });

        getRotasEmRoteirizacao().then(resp => {
            resp.sort((a, b) => {
                if (a.empresaId < b.empresaId) {
                  return -1;
                }
                if (a.empresaId > b.empresaId) {
                  return 1;
                }
                return 0;
              });

            setData(resp);


            var statusCountt = resp.reduce((acc, curr) => {
                const status = curr.statusRoteirizacao;
                if (acc[status]) {
                  acc[status]++;
                } else {
                  acc[status] = 1;
                }
                return acc;
              }, {});
            setStatusCount(statusCountt);

        }).catch(error => {
            props.openSnackbar("Ocorreu algum erro", "error");
        });
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            sx={{
                width: "100%",
                height: "auto"
            }}
        >
                <Container>
                <Typography variant="h5" gutterBottom>
        Rotas em roteirização - Atualizado em  {time === '' ? '' : getFormatTimeToString(time)}
      </Typography>
      <StatusList opcaoSituacaoRoteirizacao={opcaoSituacaoRoteirizacao}></StatusList>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Empresa</TableCell>
              <TableCell>ID da Rota</TableCell>
              <TableCell>Paradas</TableCell>
              <TableCell>Início da Roteirização</TableCell>
              <TableCell>Container</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((rota) => (
              <TableRow key={rota.rotaId} sx={{ backgroundColor: (rota.statusRoteirizacao === 4 ? VERMELHO_PASTEL:'') }}>
                <TableCell>{rota.empresaId} - {rota.empresaNome}</TableCell>
                <TableCell>{rota.rotaId}</TableCell>
                <TableCell>{rota.quantidadeDeParadas}</TableCell>
                <TableCell>{calcularDiferencaDatasParaString(rota.roteirizacaoInicio, new Date())}</TableCell>
                <TableCell>{rota.nomeContainer}</TableCell>
                <TableCell>{opcaoSituacaoRoteirizacao.find(x=>x.value === rota.statusRoteirizacao).label }</TableCell>
                <TableCell>
                    <ButtonRoteirizar routeId={rota.rotaId} statusRoteirizacao={rota.statusRoteirizacao} ></ButtonRoteirizar>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
        </Grid>
    )
}

const ConfigWithRouter = withRouter(Page);

class RotaSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <ConfigWithRouter
                        openSnackbar={openSnackbar}
                        {...this.props} >
                    </ConfigWithRouter>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default RotaSnackBar;