
import React, {useEffect} from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getAll, addLocaisInRota } from '../../API/Local';
//import { EntregasEditRoute } from '../../Router/Routing';
import { columnsGrid } from './Config';

import Button from '../../Components/Button/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [loadingAdd, setLoadingAdd] = React.useState(false);

    async function loadRow()
    {
        setRows([]);
        setLoading(true);
        getAll().then((value) => {
            if (value !== undefined)
                setRows(value);
            setLoading(false);
        });

        setColumns(columnsGrid);
    }

    async function addLocalByRota(ids, openSnackbar)
    {
        setLoadingAdd(true); 
        var idRota = props.match.params.id;
        
        var retorno = await addLocaisInRota(idRota, ids);
        if (retorno.result === 'ok') {
            openSnackbar('Locais adicionadas na rota!');
            history.goBack();
        } else {
            openSnackbar('Erro ao adicionar na rota!', 'error');
            throw true;
        }

        setLoadingAdd(false); 
    }

    useEffect(async () => {
        loadRow();
    }, [])

    let history = useHistory();

    function adicionarNaRota(ids) { 
        return <>
        <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
         <Tooltip title="Adicionar locais planejados na rota">
             <div style={{ width: '354px', height:"49px"}}>
           <Button variant="contained" color="primary"  onClick={() => { addLocalByRota(ids, openSnackbar) }} loading={loadingAdd}>
              Adicionar na rota
            </Button>
            </div>
        </Tooltip>
         )}
         </SharedSnackbarConsumer>
        </>
        
        }

    
    return (
        
                    <GridTable title="Selecionar locais planejados para adicionar na rota" 
                        rows={rows} 
                        columns={columns} 
                        //LinkEdit={EntregasEditRoute} 
                        loading={loading} 
                        CanRemove={false}
                        actionsWhenSelect={adicionarNaRota}
                    />
               

            
    );
}