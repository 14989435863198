import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { getById } from '../../API/NotasFiscais';
import { getByIdByPessoa as getEnderecoByPessoa } from '../../API/Enderecos';
import { camposForm as camposFormNF } from './NotaFiscal/Config';
import { camposForm as camposFormCTE } from './CTe/Config';
import { getUser } from '../../Router/auth';
import { getFormatDataToDataApi } from '../../Utils/FormatDateTime';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import GridList from '../ItemNotaFiscal/GridList';
import GridListCTE from './CTe/GridListCTE';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@mui/material';
import { tipoDocumento } from '../../Enums/TipoDocumento';

class FormConfig extends Component {
    constructor(props) {
        super(props);

        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;
        this.state = { loading: l, loadingField: true, values: {}, camposForm: [], title: "", ehNF: true };
    }

    changeDestinatario = value => {
        return this.changeDestinatarioById(value.target.value);
    }

    changeDestinatarioById = async id => {
        var retorno = await getEnderecoByPessoa(id);
        if (retorno !== undefined) {
            var informacao = retorno.logradouro + ", " + retorno.numero + ", " + retorno.bairro + ", " + retorno.nomeMunicipio;

            return {
                campos: [
                    { campo: 'endereco', value: informacao },
                    { campo: 'enderecoId', value: retorno.id }
                ]
            };
        }
    }

    async componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {
            getById(id).then(async (value) => {
                if (value !== undefined) {
                    this.setState({ values: value });

                    if (value.tipoDocumento === tipoDocumento.NF || value.tipoDocumento === tipoDocumento.Pedido || value.tipoDocumento === tipoDocumento.Coleta) {
                        this.setState({ ehNF: true });
                    } else {
                        this.setState({ ehNF: false });
                    }

                }
                this.setState({ loading: false });
            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {

            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    empresaId: getUser().empresaId,
                    data: getFormatDataToDataApi(new Date()),
                    situacao: 7
                }
            }));
        }

        this.setState({ loadingField: false });
    }

    render() {
        var campos = this.state.camposForm;
        var title = this.state.title;
        let id = this.props.match.params.id;
        
        if (!this.state.ehNF) {
            campos = camposFormCTE;
            title = "CT-e";
            var itens = camposFormCTE.find(e => e.name === "conhecimentoTransporteNfs")
            itens.component = <GridListCTE filter={{ documentoId: id }} />;
        } else {
            campos = camposFormNF;
            title = "Nota Fiscal";
            var itens = camposFormNF.find(e => e.name === "produtosServicos");
            itens.component = <GridList filter={{ notaFiscalId: id }} />;
        }

        if (id) {
            if (this.state.loading) {
                return (
                    <Box style={{ alignItems: 'center' }}>
                        <CircularProgress></CircularProgress>
                    </Box>
                )
            }
            return (
                <Box>
                    <Form
                        title={title}
                        form="editNfsdf"
                        fields={campos}
                        initialValues={this.state.values}
                        loading={this.state.loading || this.state.loadingField}
                        CanNotSave={true}
                    />
                </Box>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} >
                    </FormConfig>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;