import { FieldType } from "../../Components/Form/common/FieldType";
import Form from "../Destinatario/Form";

export const opcaoSituacao = [
    { value: 1, label: 'Entregue' },
    { value: 2, label: 'Devolvido' },
    { value: 3, label: 'EntregueParcial' },
    { value: 4, label: 'Cancelada' },
    { value: 5, label: 'Reagendada' },
    { value: 7, label: 'Aguardando' }];

export const columnsGrid = [
    { label: 'Destinatário', field: 'destinatario.descricao', type: 'text', options: [], padding: 'default' },
    { label: 'Rota', field: 'rota.descricao', type: 'text', options: [], padding: 'default' },
    { label: 'Situação', field: 'situacao', type: 'option', options: opcaoSituacao, padding: 'default' },
    { label: 'Início', field: 'dataInicio', padding: 'default', type: "datetime" },
    { label: 'Fim', field: 'dataFim', padding: 'default', type: "datetime" },
    { label: 'Endereco', field: 'endereco.descricao', type: 'text', padding: 'default' }
];

export const camposForm = [
    { name: "rotaId", label: "Rota", type: "lookup_assincrono", size: 3, required: false, endpoint: "api/Rotas/lookup", linkToCriar: false, podeAlterar: false },
    { name: "situacao", label: "Situação", type: "option", size: 3, required: false, options: opcaoSituacao, disabled: true },
    { name: "data", label: "Data", type: "date", size: 2, required: false },
    { name: "dataInicio", label: "Início", type: "datetime", size: 2, disabled: true },
    { name: "dataFim", label: "Fim", type: "datetime", size: 2, disabled: true },
    { name: "destinatarioId", label: "Destinatário", type: "lookup_assincrono", size: 12, required: true, endpoint: "api/Pessoas/lookup", linkToCriar: true, componentToCriar: Form, podeAlterar: true },
    { name: "endereco", label: "Endereço", type: "text", size: 12, required: true, disabled: true },
]

export const camposForm_Edit = [
    { name: "rotaId", label: "Rota", type: "lookup_assincrono", size: 3, required: false, endpoint: "api/Rotas/lookup", linkToCriar: false, podeAlterar: false, disabled: true },
    { name: "situacao", label: "Situação", type: "option", size: 3, required: false, options: opcaoSituacao, disabled: true },
    { name: "data", label: "Data", type: "date", size: 2, disabled: true },
    { name: "dataInicio", label: "Início", type: "datetime", size: 2, disabled: true },
    { name: "dataFim", label: "Fim", type: "datetime", size: 2, disabled: true },

    { name: "destinatarioId", label: "Destinatário", type: "lookup_assincrono", size: 6, required: true, endpoint: "api/Pessoas/lookup", linkToCriar: true, componentToCriar: Form, podeAlterar: true, disabled: true },
    { name: "endereco", label: "Endereço", type: "text", size: 6, required: true, disabled: true },
    { name: "comentario", label: "Comentário", type: "text", size: 12, required: false, disabled: true },
    { grupo: "Valores", name: "valorOutros", label: "Valor outros", type: FieldType.MONEY, size: 2, required: false, disabled: false }
]
