// REMOVER Esse enum,pois já tem um enum com esses valores
// Acredito que seja com o nome Roles
export const tipoPessoaPortal = {
    Motorista: 1,
    Monitoramento: 2,
    Master: 3,
    Automacao: 4,
    RLuOKTh1Ko: 5,
    Roteirizador: 7,
    AnaliseDeLogistica: 6,
    Background: 8,
    SistemaExterno: 9
}
