
import React, { useEffect } from 'react';
import { GridTable } from '../../Components/GridTable/GridTable';
import { getEntregasPendentes2, addEntregasInRota, duplicaAddEntregasInRota } from '../../API/Entregas';
import { EntregasEditRoute2 } from '../../Router/Routing';
import { columnsGrid, columnsGrid_160 } from './Config';

import Button from '../../Components/Button/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import { getEmpresa, getUser } from "../../Router/auth";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function GridList(props) {
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [loadingAdd, setLoadingAdd] = React.useState(false);
    const [loadingAddEntrega_160, setLoadingAddEntrega_160] = React.useState(false);
    const [pesoOriginal, setPesoOriginal] = React.useState('');

    const [peso, setPeso] = React.useState('');
    const changePeso = (event) => { setPeso(event.target.value); };

    async function carregar(filtro) {
        setRows([]);
        setLoading(true);
        getEntregasPendentes2(new Date('2020-01-01T00:00:00Z'), new Date('2222-12-01T00:00:00Z'), filtro).then((value) => {

            if (value !== undefined) {
                value.forEach(entrega => {

                    if (entrega.nfs != null && entrega.nfs.length > 0) {
                        entrega.dataEmissao = entrega.nfs[0].dataEmissao ?? entrega.data;
                        entrega.dataSaidaEntrada = entrega.nfs[0].dataSaidaEntrada;
                        
                        entrega.naturezaOperacao = entrega.nfs[0].naturezaOperacao;
                        entrega.numeroDocumento = entrega.nfs[0].numeroDocumentoFiscal ?? entrega.nfs[0].idExterno;
                        entrega.chave = entrega.nfs[0].chave;
                        entrega.pesoBruto = entrega.nfs[0].pesoBruto;
                        entrega.pesoLiquido = entrega.nfs[0].pesoLiquido;
                        entrega.pesoCalculado = entrega.nfs[0].pesoCalculado;
                        entrega.pesoCalculado2 = entrega.nfs[0].pesoCalculado2;
                        entrega.quantidadeVolume = entrega.nfs[0].quantidadeVolume;
                        entrega.quantidadePares = entrega.nfs[0].quantidadePares;
                        
                        if (entrega.nfs[0].total !== null && entrega.nfs[0].total !== undefined) {
                            entrega.valorFrete = entrega.nfs[0].total.valorFrete;
                            entrega.valorTotal = entrega.nfs[0].total.valorTotal;
                            entrega.valorOutros = entrega.nfs[0].total.valorOutros;
                            entrega.informacaoComplementar = entrega.nfs[0].informacaoComplementar;
                        }

                        if (getUser().empresaId === 160) {
                            if (entrega.valorFrete === 0) entrega.valorFrete = '';
                            if (entrega.valorOutros === 0) entrega.valorOutros = '';
                        }

                    } else {
                        entrega.dataEmissao = entrega.data;
                    }
                    entrega.endereco.logradouroNumero = entrega.endereco.logradouro + ", " + entrega.endereco.numero;
                    entrega.endereco.nomeMunicipioSigla = entrega.endereco.nomeMunicipio + " - " + entrega.endereco.sigaUF;
                });
                setRows(value);
            }
            setLoading(false);
        });

        if (getUser().empresaId === 160)
            setColumns(columnsGrid_160);
        else
            setColumns(columnsGrid);
    }

    async function addEntregaByRota(ids, openSnackbar) {
        setLoadingAdd(true);

        if (getEmpresa().id === 160) {
            if (ids.length > 1) {
                openSnackbar('Apenas uma entrega precisa ser selecionada!', 'error');
                setLoadingAdd(false);
                return;
            }

            // Abrir um dialog para selecionar a quantidade de toneladas para entrega.
            var registro = rows.find(obj => obj.id === ids[0]);
            if (registro) {
                var pe = registro.pesoCalculado === null ? registro.pesoLiquido : registro.pesoCalculado;
                setPeso(pe);
                setPesoOriginal(pe);
            } else {
                setPeso('');
            }

            handleClickOpen();
            setLoadingAdd(false);
            return;
        }

        var idRota = props.match.params.id;

        var retorno = await addEntregasInRota(idRota, ids);
        if (retorno.result === 'ok') {
            openSnackbar('Entregas adicionadas na rota!');
            history.goBack();
        } else {
            openSnackbar('Erro ao adicionar na rota!', 'error');
        }

        setLoadingAdd(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    async function handleOkAndClose(openSnackbar, ids) {
        setLoadingAddEntrega_160(true);

        if (peso > pesoOriginal) {
            openSnackbar('Peso não pode ser maior que o original.', 'error');
            setLoadingAddEntrega_160(false);
            return;
        }
        if (peso <= 0) {
            openSnackbar('Peso não pode ser menor que zero.', 'error');
            setLoadingAddEntrega_160(false);
            return;
        }

        var idRota = props.match.params.id;

        var retorno = await duplicaAddEntregasInRota(idRota, ids[0], peso);
        if (retorno.result === 'ok') {
            openSnackbar('Entrega adicionada na rota!');
            setLoadingAddEntrega_160(false);
            handleClose();
            history.goBack();
        } else {
            openSnackbar('Erro ao adicionar na rota!', 'error');
            setLoadingAddEntrega_160(false);
        }
    }

    useEffect(() => {
        carregar();
    }, [])

    let history = useHistory();

    function adicionarNaRota(ids) {
        return <>
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <Tooltip title="Adicionar entregas na rota">
                        <div style={{ width: '354px', height: "49px" }}>

                            <Button variant="contained" color="primary" onClick={() => { addEntregaByRota(ids, openSnackbar) }} loading={loadingAdd}>
                                Adicionar {ids.length} paradas na rota
                            </Button>

                            <>
                                <BootstrapDialog
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                >
                                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                        Definir a quantidade para carregar
                                    </DialogTitle>
                                    <IconButton
                                        aria-label="close"
                                        onClick={handleClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <DialogContent dividers>
                                        <Typography gutterBottom>
                                            Defina a quantidade que deve ser carregada no caminhão.
                                        </Typography>

                                        <OutlinedInput
                                            id="outlined-adornment-weight"
                                            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                            aria-describedby="outlined-weight-helper-text"
                                            type='number'
                                            inputProps={{
                                                'aria-label': 'weight',
                                            }}

                                            value={peso}
                                            onChange={changePeso}
                                        /> do total de {Number(pesoOriginal).toLocaleString('pt-BR')} Kg disponíveis
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={async () => await handleOkAndClose(openSnackbar, ids)} loading={loadingAddEntrega_160}>
                                            Salvar e fechar
                                        </Button>
                                    </DialogActions>
                                </BootstrapDialog>
                            </>

                        </div>
                    </Tooltip>
                )}
            </SharedSnackbarConsumer>
        </>

    }


    return (
        <>
            <GridTable title="Selecionar entregas para adicionar na rota"
                rows={rows}
                columns={columns}
                LinkEdit={EntregasEditRoute2}
                loading={loading}
                CanRemove={false}
                TemFiltro={true}
                atualizar={carregar}
                filtrar={carregar}
                filtrarDuranteSelecao={true}
                //actionsWhenSelect={adicionarNaRota}
                actionsWhenSelectAposFiltro={adicionarNaRota}
                rowsPerPage={100}
            />
        </>
    );
}