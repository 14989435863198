import { AZUL_PASTEL, VERMELHO_PASTEL } from '../../Utils/ColorsGrafos';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { formatarAnoData } from '../../Utils/FormatDateTime';
import { Box } from '@mui/material'; import LocalShippingIcon from '@mui/icons-material/LocalShipping'

export const opcaoSituacao = [
    { value: 1, label: 'Entregue' },
    { value: 2, label: 'Devolvido' },
    { value: 3, label: 'EntregueParcial' },
    { value: 4, label: 'Cancelada' },
    { value: 5, label: 'Reagendada' },
    { value: 7, label: 'Aguardando' }];

export const columnsGrid = [
    { label: 'Destinatário', field: 'destinatario.descricao', type: 'text', options: [], padding: 'default' },
    { label: 'Data emissão', field: 'dataEmissao', type: 'datetime', padding: 'default' },
    { label: 'Número', field: 'numeroDocumento', padding: 'default', type: "text" },
    { label: 'Chave', field: 'chave', padding: 'default', type: "text" },

    // { label: 'Peso', field: 'pesoTotal', padding: 'default' },
    // { label: 'Cubagem', field: 'cubagem', padding: 'default' },
    // { label: 'Volume', field: 'volumeTotal', padding: 'default' },
    // { label: 'Total', field: 'total', padding: 'default', type: "text" },

    { label: 'Logradouro', field: 'endereco.logradouroNumero', type: 'text', padding: 'default' },
    { label: 'Bairro', field: 'endereco.bairro', type: 'text', padding: 'default' },
    { label: 'Cidade/UF', field: 'endereco.nomeMunicipioSigla', type: 'text', padding: 'default' },
    { label: 'CEP', field: 'endereco.cep', type: 'text', padding: 'default' },

];

export const columnsGrid_160 = [
    { label: 'Número', field: 'numeroDocumento', padding: 'default', type: "text" },
    { label: 'Destinatário', field: 'destinatario.descricao', type: 'customWithRow', options: [], function: (row) => getTexto(row.destinatario.descricao, '200px') },
    { label: 'Produto / Observação', field: 'informacaoComplementar', type: 'customWithRow', padding: '500px', function: (row) => getInformacao(row) },
    
    { label: 'Fiscal - % Entregue', style: { backgroundColor: '#f5f5f5' }, field: 'pesoCalculado2', type: 'customWithRow', padding: 'default', width: 30, function: (row) => porcentagem(row, true, row.quantidadeVolume, row.pesoBruto, row.pesoLiquido) },
    { label: 'Fiscal - Entregue', style: { backgroundColor: '#f5f5f5' }, field: 'pesoBruto', type: 'customWithRow', padding: 'default', width: 30, function: (row) => getPeso(row.pesoBruto, true) },
    { label: 'Fiscal - Falta entregar', style: { backgroundColor: '#f5f5f5' }, field: 'pesoLiquido', type: 'customWithRow', padding: 'default', width: 30, function: (row) => getPeso(row.pesoLiquido, true) },


    { label: 'Grafos - % Planejada', field: 'pesoCalculado2', type: 'customWithRow', padding: 'default', width: 30, function: (row) => porcentagem(row, false, row.quantidadePares, row.pesoCalculado2, row.pesoCalculado === null ? row.pesoLiquido : row.pesoCalculado) },
    { label: 'Grafos - Falta planejar', field: 'pesoCalculado', type: 'customWithRow', padding: 'default', width: 30, function: (row) => getPeso(row.pesoCalculado === null ? row.pesoLiquido : row.pesoCalculado, false) },

    { label: 'Pedido', field: 'dataEmissao', type: 'customWithRow', padding: 'default', function: (row) => getData(row.dataEmissao) },
    { label: 'Entrega', style: { backgroundColor: '#f5f5f5' }, field: 'dataSaidaEntrada', type: 'customWithRow', padding: 'default', function: (row) => getDataEntrega(row) },

    { label: 'Frete/Ton', field: 'valorOutros', type: 'money', padding: 'default', numeric: true },
    { label: 'Frete fixo', field: 'valorFrete', type: 'money', padding: 'default', numeric: true },
    { label: 'Total', field: 'valorTotal', padding: 'default', type: "money", numeric: true },


    { label: 'Logradouro', field: 'endereco.logradouroNumero', type: 'customWithRow', function: (row) => getTexto(row.endereco.logradouroNumero, '200px') },
    { label: 'Bairro', field: 'endereco.bairro', type: 'customWithRow', function: (row) => getTexto(row.endereco.bairro, '150px') },
    { label: 'Cidade/UF', field: 'endereco.nomeMunicipioSigla', type: 'customWithRow', function: (row) => getTexto(row.endereco.nomeMunicipioSigla, '150px') },

];

function porcentagem(row, fiscal, caminhoes, pesoEntregue, faltaEntregar) {

    if (pesoEntregue === null)
        pesoEntregue = 0;
    if (faltaEntregar === null)
        faltaEntregar = 0;

    var quantidadeTotal = pesoEntregue + faltaEntregar;
    var porcentagemEntregue = (pesoEntregue / quantidadeTotal) * 100;
    var porcentagem = (porcentagemEntregue > 0 ? porcentagemEntregue.toFixed(0) + '%' : '0%')
    
    var texto = <>Entregue fiscalmente {porcentagem} do pedido.<br />
    Pedido: {quantidadeTotal.toLocaleString('pt-BR')} kg<br />
    Entregue: {pesoEntregue.toLocaleString('pt-BR')} kg<br />
    Falta entregar: {faltaEntregar.toLocaleString('pt-BR')} kg<br />
    Caminhões entregues: {caminhoes}</>;

    if (fiscal === false){
        texto = <>Planejado na Grafos {porcentagem} do pedido.<br />
    Pedido: {quantidadeTotal.toLocaleString('pt-BR')} kg<br />
    Planejado: {pesoEntregue.toLocaleString('pt-BR')} kg<br />
    Falta planejar: {faltaEntregar.toLocaleString('pt-BR')} kg<br />
    Caminhões planejados: {caminhoes}</>;
    }

    return <Tooltip
        title={
            <Typography variant="subtitle1">{texto}
            </Typography>
        }>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '150px',
        }}>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mr: 2 }}>
                    <Typography component="div" sx={{ fontSize: '14px' }}> {porcentagem} </Typography>
                </Box>
                <LocalShippingIcon sx={{ mr: 1, fontSize: '18px', color: 'gray' }} />
                <Typography variant="body2" component="span"> {caminhoes === null ? 0 : caminhoes} </Typography>
            </Box>
        </Box>
    </Tooltip>;
}

function getDataEntrega(row) {

    var color = '';
    var hoje = new Date();
    var inicioAmanha = new Date(hoje);
    inicioAmanha.setDate(hoje.getDate() + 1);
    inicioAmanha.setHours(0, 0, 0, 0);
    hoje.setHours(0, 0, 0, 0);

    // Se for passado é vermelho
    if (new Date(row.dataSaidaEntrada) < hoje)
        color = VERMELHO_PASTEL;
    //Se for hoje ou amanha, é amarelo, atenção
    else if (new Date(row.dataSaidaEntrada) <= inicioAmanha)
        color = AZUL_PASTEL;
    //Preto será no futuro

    return <Tooltip
        title={
            <Typography variant="subtitle1">Data prevista para entregar<br />Vermelho: Já passou da data prevista<br />Azul: A data prevista é hoje ou amanhã</Typography>
        }
    >
        <div style={{ display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', }}>
            <Typography gutterBottom style={{ color: color, fontSize: '14px' }} ><strong>{formatarAnoData(row.dataSaidaEntrada)}</strong></Typography>
        </div>
    </Tooltip>
}
function getData(data) {

    var dataF = new Date(data);

    return <div style={{ display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', }}>
        <Typography gutterBottom style={{ fontSize: '14px' }} >{formatarAnoData(dataF)}</Typography>
    </div>
}

function getInformacao(row) {
    if (row.informacaoComplementar !== undefined && row.informacaoComplementar.split("\n").length > 1) {
        var texto = <>{row.informacaoComplementar.split("\n")[0]}<br></br>{row.informacaoComplementar.split("\n")[1]}</>;
        return getTexto(texto, '230px')
    }
    return getTexto(row.informacaoComplementar, '230px');
}
function getTexto(texto, tamanho) {

    return <div style={{ width: tamanho }}>{texto}</div>
}

function getPeso(peso, fiscal) {
    if (peso === undefined || peso === null || peso === 0) {
        return <></>
    }

    let pesoFormatado = Number(peso);

    //return <Typography component="div" sx={{ fontSize: '14px' }}> {pesoFormatado.toLocaleString('pt-BR')} Kg </Typography> ;
    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '110px',
    }}>
        <Typography component="div" sx={{ fontSize: '14px' }}> {pesoFormatado.toLocaleString('pt-BR')} Kg </Typography>
    </Box>;


}
