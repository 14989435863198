
import { getFormatMinutosToStringExt } from '../../Utils/FormatDateTime';
import { Typography } from '@material-ui/core';
import { verdePastel, vermelhoPastel } from '../../Components/Map/MarkerColors';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import { RotasEditRoute } from '../../Router/Routing';

export const opcaoSituacao = [{ value: 1, label: 'Aguardando início' }, 
{ value: 2, label: 'Em rota' }, { value: 3, label: 'Finalizada' }, { value: 5, label: 'Finalizada automaticamente' }, { value: 4, label: 'Cancelada' }];

export const columnsGrid = [
    { label: 'Região', field: 'regiaoDescricao', type: 'customWithRow', options: [], padding: 'default', function: (c) => { return c.regiaoDescricao } },
    { label: 'Veículo', field: 'veiculoDescricao', type: 'customWithRow', options: [], padding: 'default', function: (c) => { return c.veiculoDescricao } },
    { label: 'Motorista', field: 'motoristaDescricao', type: 'text', options: [], padding: 'default', function: (c) => { return c.motoristaDescricao } },
    { label: 'Data', field: 'data', padding: 'default', type: "datetime" },

    { label: 'KM previsto', field: 'kmPrevisto', padding: 'default', type: "customWithRow", function: (c) => { return (c.kmPrevisto==null?'':c.kmPrevisto+' KM') } },
    { label: 'KM realizado', field: 'kmRealizado', padding: 'default', type: "customWithRow", function: resultadoKmRealizado },
    { label: 'Resultado (KM)', field: 'kmRealizado', padding: 'default', type: "customWithRow", function: resultadoKm , align:"center"},

    { label: 'Iniciou no local previsto', field: 'iniciadoLocalCorreto', padding: 'default', type: "customWithRow", function: inicioLocalCorreto, align:"center" },
    { label: 'Finalizou no local previsto', field: 'finalizadoLocalCorreto', padding: 'default', type: "customWithRow", function: finalizouLocalCorreto,align:"center" },

    //{ label: 'Tempo previsto / realizado', field: 'tempoPrevistoMinutos', padding: 'default', type: "customWithRow", function: (c) => { return getFormatMinutosToStringExt(c.tempoPrevistoMinutos) + ' / ' + getFormatMinutosToStringExt(c.tempoRealizadoMinutos) } },
    //{ label: 'Resultado do Tempo', field: 'tempoRealizadoMinutos', padding: 'default', type: "customWithRow", function: resultadoTempo },

    { label: '', field: 'abrirRota', padding: 'default', type: "customWithRow", function: abriRota, align:"right" },
];

function abriRota(row){
    return <Button size="small" onClick={()=> {window.open(RotasEditRoute+row.rotaIdInt);}}>Acessar rota</Button>;
}
// function resultadoTempo(rota) {
//     var diferenca = rota.tempoRealizadoMinutos - rota.tempoPrevistoMinutos;
//     if (rota.tempoRealizadoMinutos == 0 || rota.tempoPrevistoMinutos == null|| rota.tempoRealizadoMinutos == null)
//         return '';
//     var porcentagem = (diferenca / rota.tempoPrevistoMinutos) * 100;
//     if (diferenca < 0)
//     {
//         diferenca = diferenca * -1;
//         return componente(porcentagem.toFixed(0), ' ( -' + getFormatMinutosToStringExt(diferenca) + ')');
//     }
//     return componente(porcentagem.toFixed(0), ' (' + getFormatMinutosToStringExt(diferenca) + ')');
    
// }

function resultadoKmRealizado(c) 
{ 
    return <Tooltip
        title={
            <Typography variant="subtitle1">Considera-se: soma do percurso (do momento que ele iniciou até a finalização da rota no app) + KMs entre o local de início/fim previsto (caso o motorista iniciou/finalizou em local diferente)</Typography>
            
        }
    >
        <div style={{ alignItems: 'center'}}>
            {c.kmRealizado==null?'':c.kmRealizado+ ' KM'}
        </div>
        
    </Tooltip>;
}
function resultadoKm(rota) {
    var diferenca = rota.kmRealizado - rota.kmPrevisto;
    if (rota.kmRealizado === 0 || rota.kmPrevisto == null|| rota.kmRealizado == null)
        return '';
    var porcentagem = (diferenca / rota.kmPrevisto) * 100;
    return componente(porcentagem.toFixed(0), ' (' + diferenca.toFixed(2) + ' KM)');
}

function componente(porcentagem, valor) {
    var color = '';
    if (porcentagem <= 0)
        color = verdePastel;
    if (porcentagem >= 10)
        color = vermelhoPastel;
    return <Tooltip
        title={
            <Typography variant="subtitle1">Consideramos adequado dentro dos parâmetros previstos, uma variação de até 10%.<br/>Menor que 0% - VERDE (Ótimo)<br/>Entre 0% e 10% - PRETO (Normal)<br/>Maior que 10% - VERMELHO (Atenção!)</Typography>
            
        }
    >
        <div style={{ alignItems: 'center'}}>
            <Typography gutterBottom style={{ color: color, fontSize: '17px' }} ><b>{porcentagem+'% '}</b></Typography>
            <Typography gutterBottom style={{ color: color, marginLeft:'7px', fontSize: '14px' }} >{valor}</Typography>
        </div>
    </Tooltip>
}

function finalizouLocalCorreto(rota) {
    
    var iniciouCorretamente = rota.kmDiferencaFinal === 0;

    var color = '';
    var texto = '';
    var icone = <></>;
    
    if (rota.kmRealizado == null)
    {
        color = '';
        texto = '';
        icone = <></>;
    }else if (iniciouCorretamente)
    {
        color = verdePastel;
        icone = <CheckCircleIcon/>;
    }
    else
    {
        color = vermelhoPastel;
        icone = <CancelIcon/>;
        texto =  rota.kmDiferencaFinal.toFixed(2) + ' KM antes';
    }
    return <Tooltip
        title={
            <Typography variant="subtitle1"></Typography>
            
        }
    >
        <div style={{ alignItems: 'center'}}>
            <Typography gutterBottom style={{ color: color, fontSize: '17px' }} >{icone}</Typography>
            <Typography gutterBottom style={{ color: color, marginLeft:'7px', fontSize: '14px' }} >{texto}</Typography>
        </div>
    </Tooltip>
}

function inicioLocalCorreto(rota) {
    
    var iniciouCorretamente = rota.kmDiferencaInicial === 0;

    var color = '';
    var texto = '';
    var icone = <></>;

    if (rota.kmRealizado == null)
    {
        color = '';
        texto = '';
        icone = <></>;
    }else if (iniciouCorretamente)
    {
        color = verdePastel;
        icone = <CheckCircleIcon/>;
    }
    else
    {
        color = vermelhoPastel;
        icone = <CancelIcon/>;
        texto =  rota.kmDiferencaInicial.toFixed(2) + ' KM depois';
    }
    return <Tooltip
        title={
            <Typography></Typography>
            
        }
    >
        <div style={{ alignItems: 'center'}}>
            <Typography gutterBottom style={{ color: color, fontSize: '17px' }} >{icone}</Typography>
            <Typography gutterBottom style={{ color: color, marginLeft:'7px', fontSize: '14px' }} >{texto}</Typography>
        </div>
    </Tooltip>
}
