import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { getAll } from '../../../API/NotaFiscalItens';
import { getAll as getAllNotas } from '../../../API/NotasFiscais';
import { getById } from '../../../API/Entregas';
import { getByPessoaId } from '../../../API/JanelaHorario';
import { getById as getByIdDestinatario } from "../../../API/Pessoas";
import { getById as getLocalById } from "../../../API/Local";
import { getMotivoByParadaPlanejadaId } from '../../../API/Motivos';
import { getEmpresa } from "../../../Router/auth";
import { getFormatTimeHMToString, getFormatDataTimeToString, getFormatarDiaMesa, getFormatMinutosToStringExt, getDiasSemanas, calcularDiferencaDatasParaString2 } from '../../../Utils/FormatDateTime'
import { getById as getEnderecoById } from '../../../API/Enderecos';
import { getFormatEndereco_01 } from '../../../Utils/Endereco';

export const ParadaInformacao = (props) => {
    const [itens, setItens] = useState([]);
    const [janelas, setJanelas] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedJanela, setLoadedJanela] = useState(false);
    const [descriacao, setDescricao] = useState(props.parada.descricao);
    const [enderecoEntrega, setEnderecoEntrega] = useState(null);
    const [motivosDevolucao, setMotivosDevolucao] = useState(null);

    useEffect(() => {
        setLoaded(false);
        setLoadedJanela(false);
        setItens([]);

        if (props.parada.localId) {
            getLocalById(props.parada.localId)
                .then(parada => {
                    setDescricao(`Local: ${parada.descricao}`);
                });
        }

        if (props.parada.destinatarioId) {
            getByIdDestinatario(props.parada.destinatarioId)
                .then(visita => {
                    setDescricao(`Destinatário: ${visita.razaoSocial}`);
                });
        }

        if (props.parada.entregaId) {
            getAllNotas(props.parada.entregaId)
                .then(async (value) => {
                    if (value !== undefined) {
                        for (let index = 0; index < value.length; index++) {
                            const notaFiscal = value[index];
                            await getAll(notaFiscal.id).then((itens) => {
                                if (itens !== undefined)
                                    setItens(prev => [...prev, ...itens]);
                            });
                        }
                    }
                    setLoaded(true);
                });


            getById(props.parada.entregaId).then(async (value) => {
                if (value && value !== undefined) {
                    if (value.destinatarioId && value.destinatarioId !== undefined && value.destinatarioId > 0) {

                        getByIdDestinatario(value.destinatarioId).then(value => {
                            const desc = `Cliente: ${value.nomeFantasia ? `${value.nomeFantasia} | ` : ''}${value.razaoSocial}`;
                            setDescricao(desc);
                        });

                        getByPessoaId(value.destinatarioId)
                            .then(async (valueJanelas) => {
                                if (valueJanelas !== undefined) {
                                    for (let index = 0; index < valueJanelas.length; index++) {
                                        const janela = valueJanelas[index];
                                        var j = {
                                            inicio: getFormatTimeHMToString(new Date(janela.inicio)),
                                            fim: getFormatTimeHMToString(new Date(janela.fim)),
                                            diasDaSemana: [janela.diaDaSemana]
                                        }

                                        let lii = janelas.find(el => el.inicio === janela.inicio && el.fim === janela.fim);

                                        if (lii && lii !== undefined) {
                                            var rr = lii.diasDaSemana.find(x => { return x === janela.diaDaSemana });
                                            if (rr === undefined)
                                                lii.diasDaSemana.push(janela.diaDaSemana);
                                        } else {
                                            janelas.push(j);
                                            setJanelas(janelas);
                                        }
                                    }
                                }
                                setLoadedJanela(true);
                            });

                        if (value.enderecoEntregaId) {
                            getEnderecoById(value.enderecoEntregaId)
                                .then(resp => { setEnderecoEntrega(resp) });
                        }
                    }
                }
            });
        }

        if (props.parada.id) {
            getMotivoByParadaPlanejadaId(props.parada.id)
                .then(resp => {
                    if (resp != null) {
                        setMotivosDevolucao(resp.data);
                    }
                });
        }
    }, []);

    const mostrarProdutos = () => {
        return (
            <>
                {loaded && itens.length > 0 ? (
                    <>
                        <Typography variant="body2">
                            <b>Produtos:</b> <br />
                            {itens.slice(0, 2).map(e => {
                                return (<>
                                    <b>{e.quantidadeComUnidadeComercial}</b><font color="#6a6a6a00">. . .</font>{e.descricao.substring(0, 22)}<br />
                                </>)
                            })}
                            {itens.length > 2 ? <em> e mais {itens.length} produtos</em> : <></>}
                            <br />
                        </Typography>
                    </>
                ) : (
                    <Typography variant="body2">
                        Carregando produtos...<br />
                    </Typography>
                )
                }
            </>
        )
    }

    return (
        <div>
            <Typography variant="subtitle1"><b>{props.tipo}</b></Typography>
            <Typography variant="body2">{descriacao}</Typography>

            {enderecoEntrega && (
                <Typography variant="body2">Endereço: {getFormatEndereco_01(enderecoEntrega)}</Typography>
            )}

            <br></br>
            {props.emTransito ?
                <Typography variant="body2">
                    <b>Data: </b> {getFormatDataTimeToString(props.parada.dataFim)}<br />
                </Typography>
                :
                (props.parada.tipo === 0 || props.parada.tipo === 4 ?
                    <>
                        <Typography variant="body2">
                            <b>Data: </b> {getFormatDataTimeToString(props.parada.dataInicio)}<br />
                        </Typography>
                    </> :
                    <>
                        <Typography variant="body2">
                            {props.parada != null && props.parada.comentario !== ' ' && props.parada.comentario !== '' && props.parada.comentario !== null && props.parada.comentario !== undefined ?
                                <><b>Comentário: </b> {props.parada.comentario}<br /><br /></>
                                : <></>}
                        </Typography>

                        <Typography variant="body2">
                            <b>Tempo automático</b> <br></br>
                            {props.parada.totalMinutos > 0 ?
                                <>{getFormatMinutosToStringExt(props.parada.totalMinutos)} ({getFormatarDiaMesa(props.parada.dataInicio)} - {getFormatTimeHMToString(props.parada.dataInicio)}h as {getFormatTimeHMToString(props.parada.dataFim)}h)<br /></>
                                : <>--:--<br /></>}

                            <br></br>

                            {(getEmpresa().chegadaAoClienteObrigatoria === true && props.parada.chegadaAoClienteHistorico != null) ?
                                <>
                                    <b>Tempo cronometrado</b> <br></br>
                                    {props.parada.chegadaAoClienteHistorico.map((j, i) => {
                                        return (
                                            <>
                                                {(props.parada.chegadaAoClienteHistorico.length - i)}ª ida: {calcularDiferencaDatasParaString2(j.dataChegada, j.dataSaida)} ({getFormatarDiaMesa(j.dataChegada)} - {getFormatTimeHMToString(j.dataChegada)}h as {getFormatTimeHMToString(j.dataSaida)}h)<br />
                                            </>
                                        )
                                    })}
                                    {(props.parada.chegadaAoClienteHistorico === null || props.parada.chegadaAoClienteHistorico === undefined || props.parada.chegadaAoClienteHistorico.length === 0) && (props.parada.chegadaAoDestinoDate !== null && props.parada.chegadaAoDestinoDate !== undefined) ?
                                        <>
                                            {calcularDiferencaDatasParaString2(props.parada.chegadaAoDestinoDate, props.parada.dataRealizada)} ({getFormatarDiaMesa(props.parada.chegadaAoDestinoDate)} - {getFormatTimeHMToString(props.parada.chegadaAoDestinoDate)}h as {getFormatTimeHMToString(props.parada.dataRealizada)}h) <br />
                                        </> : <></>
                                    }
                                    <br></br>
                                </> :
                                <>
                                    <b>Clicou em "Entregue / Devolvido": </b> {getFormatDataTimeToString(props.parada.dataRealizada)}<br />
                                </>}


                            {/* chegadaAoClienteHistorico */}

                        </Typography>

                        <Typography variant="body2">
                            <b>Sequência planejada/realizada: </b>{props.parada != null && props.parada.sequencia >= 0 ? (props.parada.sequencia + 1) : " - "} / {props.parada != null && props.parada.sequenciaRealizada > 0 ? props.parada.sequenciaRealizada : (" - ")}<br />
                            <br />

                            {(motivosDevolucao != null) ? (
                                <>
                                    <b>Motivo(s): </b>
                                    {motivosDevolucao
                                        .map(item => item.titulo)
                                        .filter(Boolean)
                                        .join(", ")}
                                    <br />
                                    <br />
                                </>
                            ) : <></>}

                            <b>Previsão de horário</b> <br />
                            Chegada: {(props.parada != null ? getFormatTimeHMToString(props.parada.chegadaPrevista) : '--:--')} ... Saída: {(props.parada != null ? getFormatTimeHMToString(props.parada.saidaPrevista) : '--:--')}
                            <br />
                            <br />
                            <b>Janela de entrega:</b><br />
                            {loadedJanela ? <>
                                {janelas.length > 0 ? janelas.map(j => {
                                    return <>{getDiasSemanas(j.diasDaSemana)}: {j.inicio} - {j.fim}<br />
                                    </>
                                })

                                    : <> Nenhuma janela</>}
                            </> :
                                <>Carregando...<br />
                                </>}

                        </Typography>

                        <br></br>
                        {props.parada.tipo === 3 ?
                            (<>
                                {mostrarProdutos()}
                            </>) : (<></>)}
                    </>
                )
            }
        </div>
    );
}