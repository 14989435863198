import { AccordionDetails, AccordionSummary, Box, Card, CardContent, Container, Grid, List, ListItem, Skeleton, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { ConsultarValorPagarMotorista } from '../../API/Rotas';
import { getFormatDataTimeToString } from '../../Utils/FormatDateTime';
import { formatarNumeroParaReais } from '../../Utils/Money';
import { StatusRota } from '../../Enums/StatusRota';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

function ConsultarValorMotorista() {
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    const [selectedInitDate, setSelectedInitDate] = useState(firstDay);
    const [selectedEndDate, setSelectedEndDate] = useState(lastDay);

    const [expanded, setExpanded] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [isLoadingResponse, setIsLoadingResponse] = useState(true);

    useEffect(() => {
        ConsultarValorPagarMotorista(selectedInitDate, selectedEndDate)
            .then(resp => {
                setIsLoadingResponse(true);
                setResponseData(null);
                if (resp != null) {
                    setResponseData(resp);
                }
            })
            .catch(err => {
                setResponseData(err.response);
            })
            .finally(_ => {
                setIsLoadingResponse(false);
            });
    }, [selectedInitDate, selectedEndDate])

    const getValorTotalRota = (entregas) => {
        const sum = entregas.reduce((accumulator, entrega) => {
            return accumulator + entrega.valorOutros;
        }, 0);

        return sum;
    }

    const getStatusRota = (status) => {
        const statusMap = {
            [StatusRota.AguardandoInicio]: "Aguardando início",
            [StatusRota.EmRota]: "Em rota",
            [StatusRota.Finalizada]: "Finalizada",
            [StatusRota.Cancelada]: "Cancelada",
            [StatusRota.FinalizadaAutomaticamente]: "Finalizada automaticamente"
        };

        return statusMap[status] || 'Status desconhecido';
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                margin: "20px 0px"
            }}
        >
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <KeyboardDatePicker
                        size="small"
                        label="Data de início"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        variant='inline'
                        value={selectedInitDate}
                        onChange={(date) => setSelectedInitDate(date)}
                        invalidDateMessage='Data invalida'
                        style={{ width: '100%' }}
                        required={true}
                        validate={true}
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <KeyboardDatePicker
                        size="small"
                        label="Data de fim"
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                        variant='inline'
                        value={selectedEndDate}
                        onChange={(date) => setSelectedEndDate(date)}
                        invalidDateMessage='Data invalida'
                        style={{ width: '100%' }}
                        required={true}
                        validate={true}
                        fullWidth
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <Card
                        sx={{
                            borderRadius: 2,
                            boxShadow: 3,
                        }}
                    >
                        <CardContent
                            sx={{
                                padding: "12px !important"
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                Resumo do Período
                            </Typography>
                            <Box sx={{ mt: 1, mb: 1 }}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1">
                                        Rotas:&nbsp;
                                    </Typography>
                                    {isLoadingResponse ? (
                                        <Skeleton sx={{ flexGrow: 1 }}/>
                                    ) : (
                                        <Typography variant="body1" display="inline">
                                            {responseData.data.rotas != null ? responseData.data.rotas.length : 0}
                                        </Typography>
                                    )}
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" noWrap>
                                        Valor total:&nbsp;
                                    </Typography>
                                    {isLoadingResponse ? (
                                        <Skeleton sx={{ flexGrow: 1 }}/>
                                    ) : (
                                        <Typography variant="body1" display="inline">
                                            {formatarNumeroParaReais(responseData.data.valorTotal != null ? responseData.data.valorTotal : 0)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12}
                    spacing={0}
                >
                    {!isLoadingResponse ? (
                        responseData.data != null && responseData.data.rotas != null && responseData.data.rotas.length > 0 ? (
                            responseData.data.rotas.map((item, index) => (
                                <Accordion
                                    key={index}
                                    expanded={expanded === index}
                                    onChange={() => setExpanded(index === expanded ? null : index)}
                                    sx={{
                                        padding: "0px"
                                    }}
                                >
                                    <AccordionSummary
                                        sx={{
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <Typography variant="body2" fontSize={14}>
                                                    {item.rotaRegiao != null ? item.rotaRegiao.descricao : "SEM REGIÃO"} - {getFormatDataTimeToString(item.data)}
                                                </Typography>
                                                <Typography variant="body2" fontSize={14}>
                                                    {getStatusRota(item.status)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Typography variant="body2" fontWeight="bold" fontSize={14} textAlign="end">
                                                        {formatarNumeroParaReais(getValorTotalRota(item.entregas))}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2" fontWeight="bold">
                                            Paradas:
                                        </Typography>
                                        <List>
                                            {item.entregas.map((entregaItem, index) => (
                                                <ListItem
                                                    key={item.id}
                                                    sx={{
                                                        backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                                                        padding: "2px"
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2">
                                                                {entregaItem.destinatario.razaoSocial}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Typography variant="body2">
                                                                {entregaItem.documentos.map(doc => doc.numeroDocumentoFiscal).join(", ")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body2" textAlign="end">
                                                                {formatarNumeroParaReais(entregaItem.valorOutros)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        ) : (
                            <Box>
                                <Typography textAlign="center">
                                    {responseData.data.message}
                                </Typography>
                            </Box>
                        )
                    ) : (
                        [1, 2, 3].map((item, index) => (
                            <Skeleton
                                key={index}
                                variant="rectangular"
                                fullWidth
                                height={50}
                                sx={{
                                    marginTop: "10px"
                                }}
                            />
                        ))
                    )}
                </Grid>
            </Grid>
        </Container >
    )
}

export default ConsultarValorMotorista;