import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getById } from '../../API/Usuarios';
import { SubmissionError } from 'redux-form'
import { camposForm } from './Config';
import { getEmpresa, getUser } from '../../Router/auth';
import { UsuariosNewRoute } from '../../Router/Routing';
import { tipoPessoaPortal } from '../../Enums/TipoPessoaPortal';

class FormConfig extends Component {
    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id) {
            l = true;
        }
        this.state = {
            alertSucess: false,
            alertError: false,
            loading: l,
            values: {
                userName: ".",
                passwordHasher: "",
                ativo: true
            },
            camposForm: camposForm
        };
    }

    create = async values => {
        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            this.setState({ alertError: true });
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    update = async values => {
        var id = this.props.match.params.id;
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            this.setState({ alertError: true });
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
    }

    limpar = () => {
        this.setState({ alertSucess: false, alertError: false });
    }

    ajustarUsername = (username) => {
        let prefixo = getEmpresa().prefixo;

        if (prefixo === null || prefixo === undefined) {
            return username;
        }

        if (!username.startsWith(prefixo)) {
            return username;
        }

        return username.substring(prefixo.length, username.length);
    }

    ativarCampoRegiao = (tipoPessoa) => {
        var campos = [...camposForm];
        if (tipoPessoa === tipoPessoaPortal.Motorista) {
            campos.push({ grupo: "Configuracões", name: "rotaRegiaoId", label: "Região do usuário", type: "lookup_assincrono", endpoint: "api/RotaRegioes/lookup", size: 3, required: false, options: [] });
        }

        this.setState({ camposForm: campos });
    }

    change = (e) => {
        this.ativarCampoRegiao(e.target.value);
    }

    async componentDidMount() {
        let id = this.props.match.params.id;

        if (id) {
            getById(id)
                .then((value) => {
                    if (value !== undefined) {
                        value.userName = this.ajustarUsername(value.userName);
                        this.setState({ values: value });
                        this.ativarCampoRegiao(value.tipo);
                    }
                    this.setState({ loading: false });
                });
        }
        else {
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    empresaId: getUser().empresaId
                }
            }));
        }

        var campos = [...camposForm];

        var tipo = campos.find(e => e.name === "tipo");
        tipo.onChange = this.change;

        this.setState({ camposForm: campos });
    }

    condicoesVisible = (selectorForm, stateForm) => {
        var tipo = selectorForm("userForm", stateForm, 'tipo');
        if (tipo === 1)
            return { notMotorista: false };
        return { notMotorista: true };
    }

    render() {
        let id = this.props.match.params.id;
        if (id) {
            return (
                <Form title="Editar Usuário"
                    form="userForm"
                    fields={this.state.camposForm}
                    LinkNew={UsuariosNewRoute}
                    CanBack={true}
                    onSubmit={this.update}
                    AlertSucess={this.state.alertSucess}
                    AlertError={this.state.alertError}
                    initialValues={this.state.values}
                    loading={this.state.loading}
                    limpar={this.limpar}
                    condicoesVisible={this.condicoesVisible}>
                </Form>
            );
        } else {
            return (
                <Form title="Novo Usuário"
                    form="userForm"
                    CanNotSave={true}
                    fields={this.state.camposForm}
                    onSubmit={this.create}
                    LinkNew={UsuariosNewRoute}
                    CanBack={true}
                    AlertSucess={this.state.alertSucess}
                    AlertError={this.state.alertError}
                    initialValues={this.state.values}
                    limpar={this.limpar}
                    condicoesVisible={this.condicoesVisible}>
                </Form>
            );
        }
    }
}
export default FormConfig;
